import React, {useEffect, useState} from 'react';

import { PageHeader, Button, Tabs, Loading, Input, Message } from '@labqube/components';
import { useShareUrl } from '@labqube/hooks';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from '@labqube/react-draft-wysiwyg';
import {getTestCase} from "../../../services/test-cases";
import {getTestCaseSteps} from "../../../services/test-case-steps";
import Name from "../name";
import Steps from "../steps";
import { Link, useParams } from 'react-router-dom';
import useClipboard from 'react-use-clipboard';

import '@labqube/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';

const Viewer = () => {
    const [testCase, setTestCase] = useState({});
    const [testCaseSteps, setTestCaseSteps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [errorLoadingTestCase, setErrorLoadingTestCase] = useState(false);

    const { id:testCaseId } = useParams();

    const shareUrl = useShareUrl('ac.testCaseId', testCaseId);
    const [isCopied, setCopied] = useClipboard(shareUrl, { copiedDuration: 3000 });
    const [isPopupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        Promise.all([
            getTestCase(testCaseId),
            getTestCaseSteps(testCaseId),
        ]).then(([testCase, testCaseSteps]) => {
           setTestCase(testCase);
           setTestCaseSteps(testCaseSteps);
           setLoading(false);
            if (testCase.preconditions) {
                const rawContent = JSON.parse(testCase.preconditions); // Asegúrate de que `preconditions` sea un JSON válido
                const contentState = convertFromRaw(rawContent);
                setEditorState(EditorState.createWithContent(contentState));
            }
        }).catch(e => {
            console.log(e);
            setLoading(false);
            setErrorLoadingTestCase(true);
        })
    }, [testCaseId]);

    if (loading) {
        return (
            <Loading size='large'>
                <p>
                    Loading test case...
                </p>
            </Loading>
        )
    }

    if (errorLoadingTestCase) {
        return (
            <div style={{ padding: 41 }}>
                <PageHeader
                    title={'View test case'}
                    breadcrumbs={[{
                        href: <Link to={'/test-cases'}>Test cases</Link>,
                    }, {
                        href: ``,
                        text: 'View test case'
                    }]}
                />
                <Message 
                    title={'Error loading the test case'} 
                    description={'Please check that the link is correct or try again later.'} 
                />
            </div>
        )
    }

    const handlePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    const handleCopy = () => {
        setCopied();
        handlePopup();
    };

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title={'View test case'}
                breadcrumbs={[{
                    href: <Link to={'/test-cases'}>Test cases</Link>,
                }, {
                    href: ``,
                    text: 'View test case'
                }]}
                action={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="popup-container">
                            {isCopied && <span style={{ color: 'black', marginRight: 10 }}>Copied to clipboard!</span>}
                                <Button type={'default'} onClick={handlePopup} style={{ marginRight: 10, height: 37 }}>
                                    <i className="fa-solid fa-share-nodes"></i>
                                </Button>
                            {isPopupVisible && (
                                <div className="popup">
                                    <div className="popup-content">
                                        <Input value={shareUrl} style={{ width: '200px' }} readOnly />
                                        <Button type={'default'} onClick={handleCopy} style={{ marginLeft: 10, wordBreak: 'normal' }}>Copy</Button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Link to={`/test-cases/${testCaseId}/edit`}>
                            <Button type={'primary'} style={{height: 35}}>
                                Edit test case
                            </Button>
                        </Link>
                    </div>
                }
            />

            <Name name={testCase.name} description={testCase.description} />
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ width: '60%', margin: 'auto' }}>
                    <p>Precondition</p>
                    <Editor
                        editorState={editorState}
                        readOnly={true}
                        toolbarHidden={true}
                        wrapperClassName='demo-wrapper'
                        editorClassName='demo-edit'
                    />
                </div>
            </div>
            <br/>
            <br/>
            <Tabs style={{ width: '60%', marginLeft: '20%'}} items={[
                {
                    key: 'steps',
                    title: 'Steps',
                    content: <Steps steps={testCaseSteps} />,
                }
            ]} />
        </div>
    )
};

export default Viewer;
